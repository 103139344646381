import React from "react"
import { StaticImage, getImage} from "gatsby-plugin-image"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Banner from "../components/banner"
import TrustedBy from "../components/hireVa/trusted"
import SaveTime from "../components/hireVa/savetime"
import GoodChoice from '../components/hireVa/goodchoice'
import PostingRole from '../components/hireVa/postingrole'
import Assistant from '../components/hireVa/assistant'
import Booking from '../components/hireVa/booking'

import Testimonials from "../components/testimonials"
import Faqs from "../components/faqs.js"
import GetInTouch from  '../components/hireVa/getintouch'
import { useStaticQuery, graphql, Link } from "gatsby"



const PmService = ({ data }) => { 

    
    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
      };

      const banner_title  = ['Fully Managed', 3000, 'Fully Trained ', 2000];
      const banner_button = 'Hire Vetted Virtual Assistant';


return (

    <Layout>
<Seo title="Find the best freelance Virtual Assistants as per Demand  " />
    <Banner title = {banner_title} sticky_first = "Hire" sticky_second  = "Project Manager" button = {banner_button} />

    <section className = "inro" >
<div className = "container">
  <p>All of our project manager skilled with at least one of Tool !</p>
</div>
</section>

      {/* sfsdfsf  <div className = "vr_banner inner">
        <StaticImage
                            src="../images/PM-banner.png"
                            quality = "90"
                            layout = "fullWidth"
                            alt="A Gatsby astronaut"
                            placeholder="tracedSVG"
                            />

        <div className = "details container">
        <div className = "details_wrap">

            <h1>Let Project Manager VA handle your Projects  </h1>
            <p>
            Delegate all your Projects to Project Manager VA. Our global HR VA are flexible and trained for all kind of Project Planning.   
            </p>
            <Link to = "https://api.leadconnectorhq.com/widget/appointment/generalcalendar-estorecompany/discoverycall">Hire Project Manager VA</Link>
        </div>
        </div>


        </div>

*/} 

<section className = "trained serv_det">

    <div className="container">


        <ul>


        <li>
        <StaticImage
            src="../images/logo1.png"
            quality = "100"
          width = "140"
            alt="A Gatsby astronaut"
            placeholder="tracedSVG"
            />
         

        </li>


        <li>
        <StaticImage
            src="../images/logo2.png"
            quality = "100"
            width = "140"
            alt="A Gatsby astronaut"
            placeholder="tracedSVG"
            />
           
        </li>


        <li>
        <StaticImage
            src="../images/logo3.png"
            quality = "100"
            width = "140"
            alt="A Gatsby astronaut"
            placeholder="tracedSVG"
            />
           
        </li>


        <li>
        <StaticImage
            src="../images/logo4.png"
            quality = "100"
            width = "140"
            alt="A Gatsby astronaut"
            placeholder="tracedSVG"
            />
          

        </li>

        <li>
        <StaticImage
            src="../images/logo5.png"
            quality = "100"
            width = "140"
            alt="A Gatsby astronaut"
            placeholder="tracedSVG"
            />
         

        </li>


        <li>
        <StaticImage
            src="../images/logo6.png"
            quality = "100"
            width = "140"
            alt="A Gatsby astronaut"
            placeholder="tracedSVG"
            />
           
        </li>


        
      







       
    </ul>

    </div>


</section>


<TrustedBy/>

<SaveTime/>

<GoodChoice/>


<section className = "trained serv_det">

    <div className="container">

      <h1>WHAT VIRTUAL PROJECT MANAGER CAN DO</h1>
      <p>Marketing agencies have many
moving parts, and they manage
projects, stakeholders, clients,
and teams. That's why the
project manager plays a crucial
role, and their job description is
to ensure deliverables reach the
client on time and within budget.
Even though this might sound
simple, it actually required a lot
of work. An average project
manager will deal with a variety
of tasks, and they include the
following:</p>


<div className = "row">

        <ul>

        <li>
        <StaticImage
            src="../images/icons-sml-1.png"
            quality = "100"
          width = "80"
            alt="A Gatsby astronaut"
            placeholder="tracedSVG"
            />
            <h4>Deadline</h4>

        </li>


        
        <li>
        <StaticImage
            src="../images/icons-sml-2.png"
            quality = "100"
            width = "80"
            alt="A Gatsby astronaut"
            placeholder="tracedSVG"
            />
            <h4>Task</h4>

        </li>



        <li>
        <StaticImage
            src="../images/icons-sml-3.png"
            quality = "100"
            width = "80"
            alt="A Gatsby astronaut"
            placeholder="tracedSVG"
            />
            <h4>Budget</h4>

        </li>



        <li>
        <StaticImage
            src="../images/icons-sml-4.png"
            quality = "100"
            width = "80"
            alt="A Gatsby astronaut"
            placeholder="tracedSVG"
            />
            <h4>Monitor</h4>

        </li>



        <li>
        <StaticImage
            src="../images/icons-sml-5.png"
            quality = "100"
            width = "80"
            alt="A Gatsby astronaut"
            placeholder="tracedSVG"
            />
            <h4>Communication</h4>

        </li>
        <li>
        <StaticImage
            src="../images/icons-sml-6.png"
            quality = "100"
            width = "80"
            alt="A Gatsby astronaut"
            placeholder="tracedSVG"
            />
            <h4>Risk</h4>

        </li>

        <li>
        <StaticImage
            src="../images/icons-sml-7.png"
            quality = "100"
            width = "80"
            alt="A Gatsby astronaut"
            placeholder="tracedSVG"
            />
            <h4>Teams</h4>

        </li>
        <li>
        <StaticImage
            src="../images/icons-sml-8.png"
            quality = "100"
            width = "80"
            alt="A Gatsby astronaut"
            placeholder="tracedSVG"
            />
            <h4>Documentation</h4>

        </li>
    </ul>
    </div>


    </div>


</section>

<section className = "service_package">
<h1>CHOOSE YOUR PLAN</h1>
<div className = "container">

   

<div class="naccs">
  <div class="grid">
   <div class="gc gc--1-of-3">
    <div class="menu">
     <div class="active"><span class="light"></span><span>Part Time VA</span></div>
     <div><span class="light"></span><span>Dedicated VA</span></div>
   
    </div>
   </div>
   <div class="gc gc--2-of-3">
    <ul class="nacc">
     <li class="active">
      <div className = "inner_data">

        <div className = "list">
        <h2>Part Time VA</h2>
          <span><i class="fa fa-check"></i> Project  onboarding and management </span>
          <span><i class="fa fa-check"></i> Setup and run the daily standups</span>
          <span><i class="fa fa-check"></i> Create the weekly project schedule reports  </span>
          <span><i class="fa fa-check"></i> Risk & Issue Management Log</span>
          <span><i class="fa fa-times"></i> Vendor Managment</span>
          <span><i class="fa fa-times"></i> Setup your workspace in project management tools </span>
          <span><i class="fa fa-times"></i> Resource Planning & Budgeting</span>
          <span><i class="fa fa-check"></i> Manage repetitive and time-consuming tasks</span>
          <span><i class="fa fa-check"></i> Vendor and Client Communication </span>
          <span><i class="fa fa-times"></i> Scheduling/Calendar management</span>
         
        </div>

        <div className = "start_now">
          <h2>$20</h2>
          <span>Per Hour</span>
          <Link to = "https://api.leadconnectorhq.com/widget/appointment/generalcalendar-estorecompany/discovery-call-for-virtual-assistant">start now</Link>
        </div>
       
     
      </div>
     </li>
     <li>
     <div className = "inner_data">

<div className = "list">
<h2>Dedicated VA</h2>
<span><i class="fa fa-check"></i> Project  onboarding and management </span>
          <span><i class="fa fa-check"></i> Setup and run the daily standups</span>
          <span><i class="fa fa-check"></i> Create the weekly project schedule reports  </span>
          <span><i class="fa fa-check"></i> Risk & Issue Management Log</span>
          <span><i class="fa fa-check"></i> Vendor Managment</span>
          <span><i class="fa fa-check"></i> Setup  your workspace in project management tools </span>
          <span><i class="fa fa-check"></i> Resource Planning & Budgeting</span>
          <span><i class="fa fa-check"></i> Manage repetitive and time-consuming tasks</span>
          <span><i class="fa fa-check"></i> Vendor and Client Communication </span>
          <span><i class="fa fa-check"></i> Scheduling/Calendar management</span>
</div>

<div className = "start_now">
  <h2>$17</h2>
  <span>Per Hour</span>
  <Link to = "https://api.leadconnectorhq.com/widget/appointment/generalcalendar-estorecompany/discovery-call-for-virtual-assistant">start now</Link>
</div>


</div>


     </li>
   
    </ul>
   </div>
  </div>
 </div>

</div>


</section>

{/** 
<section className ="will_get">


    <div className="container">

        <h1>what you will get</h1>

        <ul>
            <li><h4>Clear, organized plans improve team collaboration </h4></li>
            <li><h4>Clarification in everyone’s role eliminates confusion</h4></li>
            <li><h4>Well-defined goals improve team effectiveness</h4></li>
            <li><h4>Defined communication plan keeps your team aligned and focused </h4></li>
            <li><h4>Organize all your projects and Get status any time </h4></li>
            <li><h4>Save time and energy in many aspects </h4></li>
        </ul>


    </div>
</section>
*/}

<PostingRole/>


<Assistant/>

<Booking/>

{/** 
<section className = "service_testi">
    <div className="container">
    <h1>What clients say!</h1>
<Slider {...settings}>
      <div>
        <p>Estore Company’s VA are Professional, customer-oriented, and has excellent communication skills. The Service was delivered on time and accurate.  Talha is very accommodating to the client’s needs, It was a pleasure working with him. I highly recommend working with this Project Manager! 
</p>
        <h6>- Keith Woods </h6>
      </div>
      <div>
      <p>Having worked with a few Teams for Project Management, this estore company’s VA stands above the rest. I'm soooo lucky to have found them and will keep working with them as long as they allows me to. 

</p>
        <h6>- Thomas Thornton </h6>
      </div>
      <div>
      <p>Great working with Sheylia (Estore Company’s VA), she will really cater to your Project needs. This is my 2nd project with her and she really assisted us well. Thank you very much! 


</p>
        <h6>- Dan Moss </h6>
      </div>
      <div>
      <p>Yashfa (Estore Company’s VA) is very adaptive to any administrative services that you require. She truly cares about the success of your project and often goes beyond what was initially requested to her. I will definitely opting to her service again. 



</p>
        <h6>- David Jhon </h6>
      </div>
  
    </Slider>
    </div>
    </section>

*/}
<Testimonials/>
<Faqs/>

<GetInTouch/>

 </Layout>

)


}

export default PmService;